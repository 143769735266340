import { Outlet, useNavigate } from 'react-router-dom'
import MobileHeader from '../../headers/MobileHeader'
import { useEffect } from 'react'

const MobileLayout = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/product-customer-lookup')
    }, [])

    return (
        <div>
            <MobileHeader />
            <main>
                <Outlet />
            </main>
        </div>
    )
}

export default MobileLayout
