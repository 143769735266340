import { useEffect, useState } from 'react'

function useDynamicHeight(headerRef, desktopHeaderRef, footerRef) {
    const [contentHeight, setContentHeight] = useState(0)

    useEffect(() => {
        const adjustContentHeight = () => {
            if (
                headerRef.current &&
                desktopHeaderRef.current &&
                footerRef.current
            ) {
                const headerRect = headerRef.current.getBoundingClientRect()
                const desktopHeaderRect =
                    desktopHeaderRef.current.getBoundingClientRect()
                const footerRect = footerRef.current.getBoundingClientRect()
                const windowHeight = window.innerHeight

                setContentHeight(
                    windowHeight -
                        headerRect.height -
                        desktopHeaderRect.height -
                        footerRect.height -
                        50
                )
            }
        }

        // Initial adjustment
        adjustContentHeight()

        // Adjust on window resize
        window.addEventListener('resize', adjustContentHeight)

        return () => {
            window.removeEventListener('resize', adjustContentHeight)
        }
    }, [headerRef, footerRef])

    return contentHeight
}

export default useDynamicHeight
