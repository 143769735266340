const env = {
    APP_API_URL: process.env.REACT_APP_API_URL,
    APP_BASE_URL: process.env.REACT_APP_BASE_URL,
    APP_AUTHORITY: process.env.REACT_APP_AUTHORITY,
    APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    APP_REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
    APP_LOGOUT_REDIRECT_URI: process.env.REACT_APP_LOGOUT_REDIRECT_URI,
    APP_CLIENT_ID_BE_FOR_AUTH: process.env.REACT_APP_CLIENT_ID_BE_FOR_AUTH,
    APP_SCOPES: process.env.REACT_APP_SCOPES,
}

export default env
