import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HeaderContext } from '../../../../contexts/HeaderContext'
import { Box, IconButton } from '@mui/material'
import { ReactComponent as LogoComponent } from '../../../../assets/images/logo.svg'
import AvatarWithDropdown from '../../Avatar'

import {
    BackgroundImage,
    Container,
    HeaderTop,
    HeaderBottom,
    StyledLogo,
} from './styles'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import useDisplayElement from '../../../../hooks/useDisplayElement'
import { getCookie } from 'src/utils/cookie/getCookie'
import { useLocation } from 'react-router-dom'
import useAuth from 'src/hooks/useAuth'
import BranchList from 'src/components/BranchList'

const MobileHeader = () => {
    const { user } = useAuth()

    const showDropdown = useDisplayElement()
    const navigate = useNavigate()
    const { page } = useContext(HeaderContext)

    const location = useLocation()
    const checkPath = (path) => {
        return location.pathname !== path
    }
    return (
        <BackgroundImage>
            <Container>
                <HeaderTop>
                    <StyledLogo to='/product-customer-lookup'>
                        <LogoComponent fill='white' width='50' height='25' />
                    </StyledLogo>
                    {showDropdown ? (
                        <Box
                            style={{
                                width: '60%',
                                maxWidth: '400px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <BranchList
                                style={{ width: '90%' }}
                                size='small'
                                placeholder='Select branch'
                            />
                        </Box>
                    ) : null}

                    <AvatarWithDropdown name={user?.name || ''} />
                </HeaderTop>
                {page && checkPath('/product-customer-lookup') ? (
                    <HeaderBottom>
                        <IconButton
                            fill='white'
                            onClick={() =>
                                navigate(-1) ||
                                navigate('/product-customer-lookup')
                            }
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        {page}
                    </HeaderBottom>
                ) : null}
            </Container>
        </BackgroundImage>
    )
}

MobileHeader.propTypes = {
    page: PropTypes.string,
    showDropdown: PropTypes.bool,
    name: PropTypes.string,
}

export default MobileHeader
