import useWindowSize from 'src/hooks/useWindowSize'
import MobileLayout from 'src/components/ui/layouts/MobileLayout'
import DesktopLayout from 'src/components/ui/layouts/DesktopLayout'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

const ResponsiveLayout = () => {
    const isMobile = useWindowSize()
    const navigate = useNavigate()
    const isAuthenticatedMsal = useIsAuthenticated()
    const { inProgress } = useMsal()

    useEffect(() => {
        if (inProgress === InteractionStatus.None && !isAuthenticatedMsal) {
            navigate('/login')
        }
    }, [inProgress, InteractionStatus, isAuthenticatedMsal])

    const component = (
        <div>{isMobile ? <MobileLayout /> : <DesktopLayout />}</div>
    )

    return component
}

export default ResponsiveLayout
