import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { Box } from '@mui/material'
import Autocomplete from 'src/components/ui/Autocomplete'
import { useBranch } from 'src/contexts/BranchContex'
import { useEffect } from 'react'

const BranchList = ({ style, size, placeholder, onChange = () => {} }) => {
    const { branchList, selectedBranch, updateBranch, getBranchesAPI } =
        useBranch()

    useEffect(() => {
        if (branchList.length === 0) {
            getBranchesAPI()
        }
    }, [branchList, getBranchesAPI])

    const handleBranchChange = useCallback(
        (event, newValue) => {
            updateBranch(newValue)
            onChange(event, newValue)
        },
        [updateBranch, onChange]
    )

    return (
        <>
            <Box style={{ ...style }}>
                <Autocomplete
                    size={size}
                    options={branchList}
                    placeholder={placeholder}
                    value={
                        selectedBranch || {
                            branch_name: '',
                            branch_code: null,
                        }
                    }
                    onChange={handleBranchChange}
                />
            </Box>
        </>
    )
}

BranchList.propTypes = {
    style: PropTypes.object,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    setBranchListLoader: PropTypes.func,
}

export default BranchList
