import {
    Autocomplete as MuiAutocomplete,
    TextField as MuiTextField,
} from '@mui/material'
import styled from '@emotion/styled'

export const StyledAutocomplete = styled(MuiAutocomplete)(() => ({
    width: '100%',
    maxWidth: 600,
    color: 'var(light-black)',
    background: 'var(--white)',
    borderRadius: '5px',
    boxShadow: 'var(--box-shadow)',
}))

export const StyledTextFiled = styled(MuiTextField)(() => ({
    border: 'none',
    '& fieldset': { border: 'none' },
}))
