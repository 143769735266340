import { createContext } from 'react'

const defaultValue = {
    isAuthenticated: false,
    user: null,
    accessToken: null,
    setAccessToken: () => {},
    error: null,
    setError: () => {},
    isLoading: false,
    login: async () => {},
    logout: () => {},
}

const AuthContext = createContext(defaultValue)

export default AuthContext
