import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

export const BackgroundImage = styled(Box)({
    width: '100%',
    height: '100vh',
    backgroundImage: `url(
        'https://www.sigplc.com/~/media/Images/S/SIG-PLC/images/global-image/2880x1030-07.png'
    )`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'var(--dark-purple)',
})

export const Container = styled(Box)({
    width: '90%',
    margin: 'auto',
})

export const OutletContainer = styled(Box)({
    height: '100%',
    padding: '2rem',
    overflowY: 'scroll',
    '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
    'scrollbar-width': 'none' /* Firefox */,
    '::-webkit-scrollbar': {
        display: 'none' /* Safari and Chrome */,
    },
})

export const ProfileContainer = styled(Link)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.5rem 0',
})

export const MainContainer = styled(Box)({
    background: 'var(--white)',
    overflow: 'hidden',
})

export const PageHeaderTitle = styled(Box)({
    color: 'var(--primary-dark-green)',
    fontSize: '2rem',
    fontWeight: '700',

    svg: {
        fill: 'var(--primary-dark-green)',
    },
})

export const SyledPageTitle = styled(Box)({
    paddingLeft: '100px',
})
