import { useRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { HeaderContext } from '../../../../contexts/HeaderContext'
import useDynamicHeight from 'src/hooks/useDynamicHeight'
import { IconButton } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ReactComponent as LogoComponent } from '../../../../assets/images/logo.svg'
import DesktopHeader from '../../headers/DesktopHeader'
import Footer from '../../../ui/Footer'
import AvatarWithDropdown from '../../../ui/Avatar'
import {
    BackgroundImage,
    Container,
    ProfileContainer,
    MainContainer,
    OutletContainer,
    PageHeaderTitle,
    SyledPageTitle,
} from './styles'
import { useLocation } from 'react-router-dom'
import useAuth from 'src/hooks/useAuth'

const DesktopLayout = () => {
    const { page } = useContext(HeaderContext)
    const { user } = useAuth()
    const navigate = useNavigate()
    const headerRef = useRef(null)
    const desktopHeaderRef = useRef(null)
    const footerRef = useRef(null)
    const contentHeight =
        useDynamicHeight(headerRef, desktopHeaderRef, footerRef) ?? '400px'
    const location = useLocation()

    const checkPath = (path) => {
        return location.pathname !== path
    }

    return (
        <BackgroundImage>
            <Container>
                {/* Logo and profile*/}
                <ProfileContainer ref={headerRef} to='/product-customer-lookup'>
                    <LogoComponent fill='white' width='110' height='45' />
                    <AvatarWithDropdown name={user?.name || ''} />
                </ProfileContainer>

                {/* Desktop header and main content */}
                <DesktopHeader ref={desktopHeaderRef} />
                <MainContainer
                    style={{
                        height: `${contentHeight}px`,
                    }}
                >
                    <OutletContainer>
                        {/* Page title */}
                        {page && (
                            <PageHeaderTitle>
                                {checkPath('/product-customer-lookup') ? (
                                    <>
                                        <IconButton
                                            fill='white'
                                            onClick={() =>
                                                navigate(-1) ||
                                                navigate(
                                                    '/product-customer-lookup'
                                                )
                                            }
                                        >
                                            <ArrowBackIosIcon mr={2} />
                                        </IconButton>
                                        {page}
                                    </>
                                ) : (
                                    <SyledPageTitle> {page}</SyledPageTitle>
                                )}
                            </PageHeaderTitle>
                        )}

                        {/* Rendered pages */}
                        <Outlet />
                    </OutletContainer>
                </MainContainer>

                <Footer ref={footerRef} />
            </Container>
        </BackgroundImage>
    )
}

export default DesktopLayout
