import { useMemo, forwardRef } from 'react'
import { Typography } from '@mui/material'
import { StyledFooter } from './styles'

const Footer = forwardRef((props, ref) => {
    const currentYear = useMemo(() => new Date().getFullYear(), [])
    return (
        <StyledFooter ref={ref}>
            <Typography> {currentYear} &copy; SIG plc</Typography>
        </StyledFooter>
    )
})

export default Footer
