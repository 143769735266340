import { useCallback, useEffect, useMemo, useState } from 'react'
import { MsalProvider, useMsal } from '@azure/msal-react'
import AuthContext from './AuthContext'
import { loginRequest, msalInstance } from 'src/auth/authConfig'
import { useNavigate } from 'react-router-dom'
import { InteractionRequiredAuthError } from '@azure/msal-browser'

const AuthProviderInternal = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [user, setUser] = useState(null)
    const [accessToken, setAccessToken] = useState(null)
    // const [error, setError] = useState(null)
    // const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const { inProgress, accounts } = useMsal()

    const handleAuthenticated = useCallback((user) => {
        setIsAuthenticated(true)
        setUser(user)
    }, [])

    const handleUnauthenticated = useCallback((error = '') => {
        setIsAuthenticated(false)
        setUser(null)
        localStorage.removeItem('token')
    }, [])

    const login = useCallback(() => {
        try {
            msalInstance.loginPopup(loginRequest).then((response) => {
                const { account, accessToken } = response
                msalInstance.setActiveAccount(account)
                localStorage.setItem('token', accessToken)
                setIsAuthenticated(true)
                setUser(account)
                navigate('/product-customer-lookup')
            })
        } catch (error) {
            console.error('Error during login popup:', error)
        }
    }, [msalInstance, navigate])

    const logout = useCallback(async () => {
        const logoutRequest = {
            account: msalInstance.getActiveAccount(),
        }
        try {
            await msalInstance.logoutPopup(logoutRequest).then(() => {
                handleUnauthenticated()
                navigate('/login')
            })
        } catch (error) {
            console.error(error)
        }
    }, [msalInstance, handleUnauthenticated, navigate])

    const value = useMemo(
        () => ({
            isAuthenticated,
            user,
            accessToken,
            setAccessToken,
            login,
            logout,
        }),
        [isAuthenticated, user, accessToken, logout, login]
    )

    useEffect(() => {
        if (accounts && accounts?.length > 0) {
            handleAuthenticated(accounts[0])
        }
        // handleUnauthenticated()
    }, [accounts, handleAuthenticated])

    return (
        <>
            {/* {inProgress === InteractionStatus.Login && <Loader />} */}

            <AuthContext.Provider value={value}>
                {children}
            </AuthContext.Provider>
        </>
    )
}

const MsalAuthProvider = ({ children }) => {
    return (
        <MsalProvider instance={msalInstance}>
            <AuthProviderInternal>{children}</AuthProviderInternal>
        </MsalProvider>
    )
}

export default MsalAuthProvider
