import { loginRequest, msalInstance } from 'src/auth/authConfig'
const interactionRequiredMessages = [
    'consent_required',
    'interaction_required',
    'login_required',
    'no_account_in_silent_request',
]

const isInteractionRequired = (error) => {
    const { message } = error

    if (!message || message.length <= 0) {
        return false
    }

    return interactionRequiredMessages.some((it) => message.includes(it))
}

export const getAccessToken = async () => {
    try {
        const activeAccount = msalInstance?.getActiveAccount()
        const accounts = msalInstance?.getAllAccounts()
        if (!activeAccount && accounts.length === 0) {
            return null
        }

        const { accessToken } = await msalInstance?.acquireTokenSilent({
            scopes: loginRequest?.scopes,
            account: activeAccount ?? accounts?.[0],
        })

        return { accessToken }
    } catch (error) {
        if (isInteractionRequired(error)) {
            const { accessToken } = await msalInstance.acquireTokenPopup({
                scopes: loginRequest?.scopes,
            })
            return { accessToken }
        } else {
            throw error
        }
    }
}
