import { useState, useEffect, useCallback } from 'react'

const useWindowSize = () => {
    const [isMobile, setIsMobile] = useState(false)
    const width = 1200

    const updateTarget = useCallback((e) => {
        if (e.matches) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    useEffect(() => {
        const media = window.matchMedia(`(max-width: ${width}px)`)
        media.addEventListener('change', (e) => updateTarget(e))

        if (media.matches) {
            setIsMobile(true)
        }

        return () => media.removeEventListener('change', (e) => updateTarget(e))
    }, [])

    return isMobile
}

export default useWindowSize
