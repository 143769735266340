import { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { StyledAvatarWrapper } from './styles'
import { Avatar } from '@mui/material'
import useAuth from 'src/hooks/useAuth'

const AvatarWithDropdown = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const { user, logout } = useAuth()
    const { name } = user || ''

    // Function to generate initials for Avatar
    const stringAvatar = (name) => {
        const initials = name
            .split(' ')
            .map((word) => word[0])
            .join('')
        return {
            children: initials.toUpperCase(),
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = () => {
        logout()
        handleClose()
    }

    const open = Boolean(anchorEl)

    return (
        <>
            <IconButton onClick={handleClick} size='large'>
                <StyledAvatarWrapper>
                    <Avatar {...stringAvatar(name || '')} />
                </StyledAvatarWrapper>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleLogout}>
                    <Typography>Logout</Typography>
                </MenuItem>
            </Menu>
        </>
    )
}

export default AvatarWithDropdown
