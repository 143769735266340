import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import { Grid as MuiGrid, Typography } from '@mui/material'

export const StyledContainer = styled(Box)({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const Loader = () => (
    <StyledContainer style={{}}>
        <CircularProgress color='secondary' />
    </StyledContainer>
)

export default Loader
