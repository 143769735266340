import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { CONTAINER_MAX_WIDTH } from 'src/constants/common'
import { Link } from 'react-router-dom'

export const BackgroundImage = styled(Box)({
    width: '100%',
    height: '100%',
    backgroundImage: `url(
        'https://www.sigplc.com/~/media/Images/S/SIG-PLC/images/global-image/2880x1030-07.png'
    )`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'var(--dark-purple)',
    minHeight: '170px',
})

export const Container = styled(Box)({
    padding: '1.5rem 1rem',
    maxWidth: CONTAINER_MAX_WIDTH,
    margin: 'auto',
})

export const HeaderTop = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
})

export const HeaderBottom = styled(Box)({
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: '700',

    svg: {
        fill: 'white',
    },
})

export const StyledLogo = styled(Link)({})
