export const getCookie = (cookieName) => {
    const name = `${cookieName}=`
    const decodedCookie = decodeURIComponent(document.cookie)

    const cookieArray = decodedCookie.split(';')

    const cookie = cookieArray[0].trim()
    if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length)
    }

    return null
}
