import React, { createContext, useState } from 'react'

const defaultValue = {
    products: [],
    setProducts: () => {},
    customers: [],
    setCustomers: () => {},
    search: '',
    setSearch: (searchTerm) => {},
    currentTab: 'Product Lookup',
    setCurrentTab: () => {},
    showLoader: false,
    setShowLoader: () => {},
}

export const SearchContext = createContext(defaultValue)

export const SearchProvider = ({ children }) => {
    const [products, setProducts] = useState([])
    const [customers, setCustomers] = useState([])
    const [search, setSearch] = useState('')
    const [currentTab, setCurrentTab] = useState('Product Lookup')
    const [showLoader, setShowLoader] = useState(false)

    return (
        <SearchContext.Provider
            value={{
                products,
                setProducts,
                customers,
                setCustomers,
                search,
                setSearch,
                currentTab,
                setCurrentTab,
                showLoader,
                setShowLoader,
            }}
        >
            {children}
        </SearchContext.Provider>
    )
}
