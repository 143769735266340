export const FONTS = ['"Open Sans"', 'Roboto', 'Arial', 'sans-serif'].join(',')

export const TYPOGRAPHY = {
    fontFamily: FONTS,
    h1: {
        fontaFmily: FONTS,
    },
    h2: {
        fontFamily: FONTS,
    },
    h3: {
        fontFamily: FONTS,
    },
    h4: {
        fontFamily: FONTS,
    },
    h5: {
        fontFamily: FONTS,
    },
    h6: {
        fontFamily: FONTS,
    },
    subtitle1: {
        fontFamily: FONTS,
    },
    subtitle2: {
        fontFamily: FONTS,
    },
    body1: {
        fontFamily: FONTS,
    },
    body2: {
        fontFamily: FONTS,
    },
    button: {
        fontFamily: FONTS,
    },
    caption: {
        fontFamily: FONTS,
    },
    overline: {
        fontFamily: FONTS,
    },
}
