import { LogLevel, Configuration } from '@azure/msal-browser'
import { PublicClientApplication } from '@azure/msal-browser'
import env from 'src/constants/env'

const msalConfig: Configuration = {
    auth: {
        clientId: env.APP_CLIENT_ID,
        authority: env.APP_AUTHORITY,
        redirectUri: env.APP_REDIRECT_URI,
        postLogoutRedirectUri: env.APP_LOGOUT_REDIRECT_URI,
        clientCapabilities: ['CP1'], // this lets the resource owner know that this client is capable of handling claims challenge.
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        // console.error(message)
                        return
                    case LogLevel.Info:
                        // console.info(message)
                        return
                    case LogLevel.Verbose:
                        // console.debug(message)
                        return
                    case LogLevel.Warning:
                        // console.warn(message)
                        return
                    default:
                        return
                }
            },
        },
    },
}

export const msalInstance = new PublicClientApplication(msalConfig)

export const loginRequest = {
    scopes: [env.APP_SCOPES],
}
