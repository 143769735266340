import { useLocation } from 'react-router-dom'

const routes = ['/product-customer-lookup']

const useDisplayElement = (routesToDisplay) => {
    const location = useLocation()
    const showElement = routes.includes(location.pathname)

    return showElement
}

export default useDisplayElement
