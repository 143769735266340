import { createContext, useState } from 'react'

export const HeaderContext = createContext({
    page: '',
    setPage: () => {},
})

const HeaderProvider = ({ children }) => {
    const [page, setPage] = useState('')

    return (
        <HeaderContext.Provider
            value={{
                page,
                setPage,
            }}
        >
            {children}
        </HeaderContext.Provider>
    )
}

export default HeaderProvider
