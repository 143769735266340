import { forwardRef } from 'react'
import { Box, Typography } from '@mui/material'
import { Background } from './styles'
import { Container } from '../MobileHeader/styles'
import BranchList from 'src/components/BranchList'

const DesktopHeader = forwardRef((props, ref) => {
    return (
        <Background {...props} ref={ref}>
            <Container>
                <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Typography fontSize={13} color='white' mr={2}>
                        Branch Selector
                    </Typography>
                    <Box
                        style={{
                            width: '60%',
                            maxWidth: '400px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <BranchList
                            style={{ width: '90%' }}
                            size='small'
                            placeholder='Select branch'
                        />
                    </Box>
                </Box>
            </Container>
        </Background>
    )
})

export default DesktopHeader
