import PropTypes from 'prop-types'
import { StyledAutocomplete, StyledTextFiled } from './styles'

const Autocomplete = ({
    placeholder = '',
    options,
    value,
    onChange,
    ...rest
}) => {
    return (
        <StyledAutocomplete
            fullWidth
            options={options}
            getOptionLabel={(options) => options?.branch_name}
            value={value}
            isOptionEqualToValue={(option, value) =>
                option?.branch_name === value?.branch_name
            }
            renderInput={(params) => (
                <StyledTextFiled {...params} placeholder={placeholder} />
            )}
            onChange={onChange}
            {...rest}
        />
    )
}

Autocomplete.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.array,
}

export default Autocomplete
