import { Outlet } from 'react-router-dom'
import MsalAuthProvider from 'src/contexts/AuthProviderInternal'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import HeaderProvider from './contexts/HeaderContext'
import CssBaseline from '@mui/material/CssBaseline'
import { SearchProvider } from './contexts/SearchDataContext'
import { BranchProvider } from './contexts/BranchContex'

const App = () => {
    return (
        <MsalAuthProvider>
            <ThemeProvider theme={theme}>
                <BranchProvider>
                    <HeaderProvider>
                        <SearchProvider>
                            <CssBaseline />
                            <Outlet />
                        </SearchProvider>
                    </HeaderProvider>
                </BranchProvider>
            </ThemeProvider>
        </MsalAuthProvider>
    )
}

export default App
