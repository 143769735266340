import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
} from 'react'
import { getCookie } from 'src/utils/cookie/getCookie'
import { setCookie } from 'src/utils/cookie/setCookie'
import { get } from 'src/hooks/useAxiosInterceptors'

const BranchContext = createContext()

export const BranchProvider = ({ children }) => {
    const [selectedBranch, setSelectedBranch] = useState(null)
    const [branchList, setBranchList] = useState([])
    const getBranchesAPI = useCallback(async () => {
        try {
            const { data } = await get('branches')
            setBranchList(data?.branchlist)
        } catch (error) {
            console.log('error', error)
        }
    }, [])

    useEffect(() => {
        getBranchesAPI()
    }, [getBranchesAPI])

    useEffect(() => {
        if (!branchList?.length) return
        const cookieValue = getCookie('selectedBranch')
        if (cookieValue) {
            const [branch_name, branch_code] = cookieValue.split('-')
            setSelectedBranch({
                branch_name,
                branch_code: parseInt(branch_code),
            })
        }
    }, [branchList])

    const updateBranch = (newBranch) => {
        setSelectedBranch(newBranch)
        if (newBranch) {
            setCookie(
                'selectedBranch',
                `${newBranch?.branch_name}-${newBranch?.branch_code}`
            )
        } else {
            setCookie('selectedBranch', '', -1)
        }
    }

    return (
        <BranchContext.Provider
            value={{
                selectedBranch,
                updateBranch,
                branchList,
                setBranchList,
                getBranchesAPI,
            }}
        >
            {children}
        </BranchContext.Provider>
    )
}

export const useBranch = () => {
    return useContext(BranchContext)
}
