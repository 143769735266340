import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { lazy } from 'react'
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import ResponsiveLayout from './components/ui/layouts/ResponsiveLayout'
import useAuth from 'src/hooks/useAuth'
import Loader from './components/ui/Loader'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

const Login = lazy(() => import('./pages/login'))
const ProductCustomerLookup = lazy(() =>
    import('./pages/product-customer-lookup')
)
const Customer = lazy(() => import('./pages/customer'))
const ProductDetails = lazy(() => import('./pages/product-details'))
const OrderDetails = lazy(() => import('./pages/order-details'))
const PageNotFound = lazy(() => import('./pages/page-not-found'))

const PrivateRoute = ({ component }) => {
    const token = localStorage.getItem('token')

    return token ? component : <Navigate to='/login' />
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <ResponsiveLayout />,
                children: [
                    {
                        index: true,
                        element: <Navigate to='/login' replace />,
                    },
                    {
                        path: 'product-customer-lookup',
                        element: (
                            <PrivateRoute
                                component={<ProductCustomerLookup />}
                            />
                        ),
                    },
                    {
                        path: '/product-details/:productId',
                        element: (
                            <PrivateRoute component={<ProductDetails />} />
                        ),
                    },
                    {
                        path: '/order-details/:id',
                        element: <PrivateRoute component={<OrderDetails />} />,
                    },
                    {
                        path: '/customer',
                        element: <PrivateRoute component={<Customer />} />,
                    },
                ],
            },
            {
                path: '/login',
                element: <Login />,
            },
            {
                path: '*',
                element: <PageNotFound />,
            },
        ],
    },
])

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <React.StrictMode>
        <Suspense fallback={<Loader />}>
            <RouterProvider router={router} />
        </Suspense>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
