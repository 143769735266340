import axios from 'axios'
import env from '../constants/env'
import useAuth from './useAuth'
import { loginRequest, msalInstance } from 'src/auth/authConfig'
import { getAccessToken } from 'src/utils/getAccount'

export const axiosInstance = axios.create({
    baseURL: env.APP_BASE_URL,
    headers: {
        'content-type': 'application/json',
    },
})

export const formatUrl = (path, params = {}) => {
    const paramsString = Object.entries(params)
        .filter(([_, value]) => value !== undefined)
        .map(
            ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('?')

    if (paramsString.length > 0) {
        return `${path}?${paramsString}`
    }

    return path
}

export const get = async (path) => {
    return await axiosInstance.get(`${path}`)
}

export const post = async (
    path,
    body,
    headers,
    auth = null,
    baseUrl = env.APP_BASE_URL
) => {
    return await axiosInstance.post(`${path}`, body, {
        headers,
        auth,
    })
}

export const put = async (
    path,
    body,
    headers,
    auth = null,
    baseUrl = env.APP_BASE_URL
) => {
    return await axiosInstance.put(`${path}`, body, {
        headers,
        auth,
    })
}

export const del = async (
    path,
    body,
    headers,
    auth = null,
    baseUrl = env.APP_BASE_URL
) => {
    return await axiosInstance.delete(`${path}`, {
        headers,
        auth,
        data: body,
    })
}

axiosInstance.interceptors.request.use(
    (requestConfig) => {
        const newRequestConfig = { ...requestConfig }

        const token = localStorage.getItem('token')

        newRequestConfig.headers = {
            ...requestConfig.headers,
            Authorization: `Bearer ${token}`,
        }

        return newRequestConfig
    },
    (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    async (err) => {
        const originalConfig = err.config
        if (
            !err.response &&
            err.message === 'Network Error' &&
            !originalConfig._retry
        ) {
            originalConfig._retry = true
            try {
                const user = await getAccessToken()
                const { accessToken } = user
                localStorage.setItem('token', accessToken)
                // setAccessToken(tokenResponse.accessToken)
                axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
                return axiosInstance(originalConfig)
            } catch (_error) {
                console.error('Silent token acquisition failed:', _error)
                if (_error.response && _error.response.data) {
                    return Promise.reject(_error.response.data)
                }

                return Promise.reject(_error)
            }
        }
        return Promise.reject(err)
    }
)

export default axiosInstance
