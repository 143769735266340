import styled from 'styled-components'

export const StyledAvatarWrapper = styled.div`
    .MuiAvatar-root {
        background-color: var(--white);
        color: var(--lighter-purple);
        font-size: 16px;
        font-weight: 700;
    }
`
