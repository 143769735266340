import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const StyledFooter = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FEFEFE',
    paddingTop: '4rem',

    p: { fontSize: '0.875rem' },

    svg: {
        verticalAlign: 'bottom',
        height: '1.125rem',
    },
})
